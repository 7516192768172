@import url(https://fonts.googleapis.com/css?family=Varela+Round&display=swap&subset=latin-ext);
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Varela Round', sans-serif;
  font-size: 24px;
}
.wrapper {
  background: lightgrey;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 40px;
}

.products-list {
  width: 80vw;
  max-width: 850px;
  height: 360px;
  padding: 30px;
  display: flex;
  flex-flow: column wrap;
  align-content: space-around;
}

@media only screen and (max-width: 1024px) {
  .products-list {
    width: auto;
    height: auto;
  }
}

.product {
  margin: 5px;
  flex-basis: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px #555 solid;
  border-radius: 50px;
}

.product__name {
  padding: 5px 15px;
}

.product__buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product__quantity {
  display: block;
  width: 30px;
  text-align: center;
}

.button {
  flex: 1 1;
  width: 30px;
  height: 30px;
  margin: 5px;
  border-radius: 50%;
  background-color: lightgrey;
  border: 0px;
  font-size: 16px;
}

.button:hover {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
  color: black;
}
.button--minus {
  color: lightcoral;
}
.button--minus:hover {
  background-color: lightcoral;
}

.button--plus {
  color: lightseagreen;
}
.button--plus:hover {
  background-color: lightseagreen;
}

.result {
  padding: 15px;
  text-align: center;
}

.result__copy {
  margin-left: 10px;
  color: blue;
  cursor: pointer;
}

.result__tooltip {
  font-size: 12px;
}

